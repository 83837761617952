import * as React from 'react'
import Container from './container'

const SimpleHeader = ({ title, subtitle }) => (
  <div className='flex flex-col justify-center bg-black text-white'>
    <Container className='pb-4 pt-5 sm:pb-5'>
      <h1 className='font-semi-bold text-4vw'>
        {title}
      </h1>
      {subtitle &&
        <p className='text-silver text-md sm:text-lg'>
          {subtitle}
        </p>
      }
    </Container>
  </div>
 )

export default SimpleHeader
