import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Subnav from '../../components/subnav'
import Container from '../../components/container'
import SimpleHeader from '../../components/simple_header'
//import Icon from '../../components/icon'
import { GatsbyImage } from 'gatsby-plugin-image'

const ResourcePage = ({ data }) => {

  const content = data.contentfulResourceCategory
  const resourcePages = data.allContentfulResourceCategory.nodes
  //const isEvents = content.url === 'events_and_webinars'
  //const eventItems = data.allContentfulEventItem.nodes

  return (
    <Layout activeNav={true}>
      <Seo
        title={content.seoTitle || content.title}
        description={content.metaDescription || content.subtitle}
      />
      <SimpleHeader
        title={content.title}
        subtitle={content.subtitle}
      />
      <Subnav
        links={[
          ...resourcePages.map(item => (
            {
              key: `subnav_${item.url}`,
              to: `/resources/${item.url}`,
              text: item.title
            }
          )), {
            href: 'https://blog.roadster.com',
            text: 'Blog'
          }
        ]}
      />
      <div className='Section Section--stripe Section--condensed'>
        <Container size='lg'>
          <div className='grid gap-4 sm:grid-cols-2 md:grid-cols-3'>
            {content.items?.map(item => (
              <a
                key={`card_${item.url}`}
                href={item.url}
                target='_blank'
                rel='noopener noreferrer'
                className='flex flex-col bg-white rounded-md overflow-hidden text-gray hover:text-black shadow-sm hover:shadow-lg transition-shadow'
              >
                <div className='p-3'>
                  <h3 className='text-base font-semi-bold m-0 text-black'>
                    {item.title}
                  </h3>
                  {item.blurb &&
                    <p className='text-md m-0 mt-1/4em'>
                      {item.blurb}
                    </p>
                  }
                </div>
                {item.image &&
                  <GatsbyImage
                    image={item.image.gatsbyImageData}
                    className='mt-auto'
                  />
                }
              </a>
            ))}
          </div>
        
          {/*isEvents && <>
            <hr className='my-4 sm:my-5' />
          
            <h2 className='text-xl sm:text-2xl m-0 font-regular text-center'>
              <Icon name='calendar' />
              <span className='block mt-1/2em'>
                Calendar of Events
              </span>
            </h2>

            <div className='grid gap-2 sm:grid-cols-2 leading-tight mt-4'>
              {eventItems.map(event => {
                const hasUrl = event.url
                const hasLocation = event.location
                const dateMonth = event.date?.split(',')[0]
                const dateDay = event.date?.split(',')[1]
                const date2Month = event.date2?.split(',')[0]
                const date2Day = event.date2?.split(',')[1]
                const Component = hasUrl ? 'a' : 'div'
                return (
                  <Component
                    key={`event_item_${event.title}`}
                    className={`bg-white border-l-3 p-3 rounded-md overflow-hidden ${hasUrl ? 'hover:shadow-lg transition-shadow' : ''}`}
                    {...hasUrl && {
                      href: event.url,
                      target: '_blank',
                      rel: 'noopener noreferrer'
                    }}>
                    <div className='flex'>
                      <div className='uppercase shrink-0' style={{ width: '7.5rem' }}>
                        <Icon
                          name={hasLocation ? 'speaking' : 'webinar'}
                          style={{ fontSize: 24 }} />
                        {dateMonth &&
                          <div className='text-md font-semi-bold mt-1'>
                            {dateMonth}
                          </div>
                        }
                        {date2Month &&
                          <div className='text-gray'>
                            {dateDay}
                            {date2Month && <>
                              {' – '}
                              {dateMonth !== date2Month && date2Month}
                              {' '}
                              {date2Day}
                            </>}
                          </div>
                        }
                      </div>
                      <div className='flex-1 border-l border-light-gray pl-3'>
                        <div className='font-semi-bold uppercase text-silver' style={{ height: 24 }}>
                          {hasLocation ? 'Event' : 'Webinar'}
                        </div>
                        <div className='text-md font-semi-bold mt-1'>
                          {event.title}
                        </div>
                        <div className='flex items-center text-gray mt-1'>
                          <div className='shrink-0'>
                            <Icon
                              name={hasLocation ? 'locale' : 'url'}
                              style={{ fontSize: '1.25em' }}
                              className='shrink-0 mr-1/2em'
                            />
                          </div>
                          <div className='flex-1 truncate'>
                            {event.location || event.url}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Component>
                )
              })}
            </div>
          </>*/}
        </Container>
      </div>
    </Layout>
  )
}

export default ResourcePage

export const pageQuery = graphql`
  query ($url: String) {
    contentfulResourceCategory(url: {eq: $url}) {
      seoTitle
      metaDescription
      url
      subtitle
      title
      items {
        title
        url
        blurb
        image {
          gatsbyImageData(placeholder: BLURRED, width: 368)
        }
      }
    }
    allContentfulResourceCategory(sort: {fields: path_Id}) {
      nodes {
        title
        url
      }
    }
  }
`
